import axios from 'axios';
import { ContentPage } from '../../components/main/PageContent/contentPage';
import { defaultMetadataChars } from '../../components/metadata/characteristic-drawer/characteristicDrawerFormHelpers';
import { baseUrl } from '../../components/shared/environment';
import { DESIGNER_SEQUENCE_ID, DrawerType, NotificationLevel } from '../../shared/constants';
import { hasOpenWorkspace } from '../../shared/utils';
import {
  enqueueSnackbar,
  generateMetadataReport,
  getErrorMessage,
  openFolderDrawer,
  setOriginalDetachedReport,
} from '../ActionCreators';
import * as ActionTypes from '../ActionTypes';
import { clearWorkspace, removeReportFromWorkspace } from '../WorkspaceActionCreators';
import { getAllUsersAndGroups } from '../adminActionCreators';
import { AppThunk } from '../configureStore';
import { isDesignerDirty } from '../report/selectors';
import { ChangeWorkspaceModalStatus } from './changeWorkspaceModalStatus';
import { OverwriteConfirmationStatus } from './overwriteConfirmationStatus';
import { getActivePage, isDesignerOpen } from './selectors';
import { DEFAULT_CONTENT_PAGE } from './state';
/**
 * Page navigation
 */

export const setActivePage = (page: ContentPage) =>
  ({
    type: ActionTypes.SET_ACTIVE_PAGE,
    payload: page,
  } as const);

export const openPage = (page: ContentPage): AppThunk => (dispatch, getState) => {
  if (getActivePage(getState()) === page) {
    return;
  }

  if (page === ContentPage.ADMIN) {
    dispatch(getAllUsersAndGroups(true));
  }

  if (page === ContentPage.METADATA) {
    dispatch(
      generateMetadataReport(
        getState().user.userInfo.userPreferences?.selectedMetadataViewerFields ||
          defaultMetadataChars,
      ),
    );
  }

  dispatch(setActivePage(page));
};

export const openHomePage = () => openPage(ContentPage.HOME);
export const openWorkspacePage = () => openPage(ContentPage.WORKSPACE);
export const openDesignerPage = () => openPage(ContentPage.DESIGNER);
export const openAdminPage = () => openPage(ContentPage.ADMIN);
export const openMetadataPage = () => openPage(ContentPage.METADATA);

export const closePage = (page: ContentPage, nextPage = DEFAULT_CONTENT_PAGE): AppThunk => (
  dispatch,
  getState,
) => {
  if (getActivePage(getState()) === page) {
    dispatch(openPage(nextPage));
  }
};

export const closeDesigner = (nextPage = DEFAULT_CONTENT_PAGE) =>
  closePage(ContentPage.DESIGNER, nextPage);

/**
 * <ChangeWorkspaceModal /> actions
 */

export const setChangeWorkspaceModalStatus = (status: ChangeWorkspaceModalStatus | null) =>
  ({
    type: ActionTypes.SET_CHANGE_WORKSPACE_MODAL_STATUS,
    payload: status,
  } as const);

export const _setDisplayFunds = (displayFunds: boolean) =>
  ({
    type: ActionTypes.SET_DISPLAY_FUNDS,
    payload: displayFunds,
  } as const);

export const setDisplayFunds = (displayFunds: boolean): AppThunk => dispatch => {
  dispatch(_setDisplayFunds(displayFunds));
  axios
    .post(`${baseUrl}api/updateDisplayFunds`, null, {
      params: { displayFunds },
    })
    .catch(error => {
      dispatch(_setDisplayFunds(displayFunds));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to set display funds: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const setOverwriteConfirmationModalStatus = (status: OverwriteConfirmationStatus | null) =>
  ({
    type: ActionTypes.SET_OVERWRITE_CONFIRMATION_MODAL_STATUS,
    payload: status,
  } as const);

export const intendChangeWorkspace = (status: ChangeWorkspaceModalStatus): AppThunk => (
  dispatch,
  getState,
) => {
  // If there is no open workspace, we don't need to
  // prompt the user for confirmation.
  if (hasOpenWorkspace(getState())) {
    dispatch(setChangeWorkspaceModalStatus(status));
  } else {
    dispatch(confirmChangeWorkspace(status));
  }
};

export const confirmChangeWorkspace = (status?: ChangeWorkspaceModalStatus): AppThunk => (
  dispatch,
  getState,
) => {
  switch (status ?? getState().ui.changeWorkspaceModalStatus) {
    case ChangeWorkspaceModalStatus.CREATE_BLANK: {
      dispatch(confirmClearWorkspace());
      break;
    }

    case ChangeWorkspaceModalStatus.CREATE_FROM_TEMPLATE: {
      dispatch(openFolderDrawer(DrawerType.OPEN_WORKSPACE_TEMPLATE));
      break;
    }

    case ChangeWorkspaceModalStatus.OPEN: {
      dispatch(openFolderDrawer(DrawerType.OPEN_WORKSPACE));
      break;
    }

    default: {
      break;
    }
  }

  dispatch(closeChangeWorkspaceModal());
};

export const confirmClearWorkspace = (): AppThunk => dispatch => {
  dispatch(clearWorkspace());
  dispatch(openWorkspacePage());
};

export const closeChangeWorkspaceModal = () => setChangeWorkspaceModalStatus(null);

/**
 * <CloseDesignerModal /> actions
 */

export const intendCloseDesigner = (page: ContentPage) =>
  ({
    type: ActionTypes.INTEND_CLOSE_DESIGNER,
    payload: page,
  } as const);

export const promptCloseDesigner = (intendedPage?: ContentPage): AppThunk => (
  dispatch,
  getState,
) => {
  if (!intendedPage) intendedPage = getState().ui.previousPage || DEFAULT_CONTENT_PAGE;
  if (isDesignerOpen(getState())) {
    if (isDesignerDirty(getState())) {
      dispatch(intendCloseDesigner(intendedPage));
    } else {
      dispatch(setOriginalDetachedReport());
      dispatch(openPage(intendedPage));
      dispatch(removeReportFromWorkspace(DESIGNER_SEQUENCE_ID));
    }
  }
};

export const confirmCloseDesigner = () =>
  ({
    type: ActionTypes.CONFIRM_CLOSE_DESIGNER,
  } as const);

export const cancelCloseDesigner = () =>
  ({
    type: ActionTypes.CANCEL_CLOSE_DESIGNER,
  } as const);

export const setIsAboutOpen = (payload: boolean) =>
  ({
    type: ActionTypes.SET_IS_ABOUT_OPEN,
    payload,
  } as const);

export const setIsRemoteConsoleOpen = (payload: boolean) =>
  ({
    type: ActionTypes.SET_IS_REMOTE_CONSOLE_OPEN,
    payload,
  } as const);

export const setRemoteConsoleFocus = (payload: () => void | null) =>
  ({
    type: ActionTypes.SET_REMOTE_CONSOLE_FOCUS,
    payload,
  } as const);

export const setFoliaSyntaxGuideOpen = (open: boolean) =>
  ({
    type: ActionTypes.SET_FOLIA_SYNTAX_GUIDE_OPEN,
    payload: open,
  } as const);
