import {
  Cell,
  DateContexts,
  FullDateContextItem,
  mapData,
  Modifier,
  nextRequestId,
  nextSequenceId,
  ReportPrecisionUpdate,
  RowField,
} from 'algo-react-dataviz';
import equal from 'deep-equal';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { visualizationComponents } from '../charting/visualizationComponents';
import { GroupingLayerId } from '../components/designer-panel/drag-and-drop/groupingLayerId';
import { getSelectedPortfolios } from '../components/drawers/portfolioDrawerHelpers';
import { isOidcClientEnabled, isPortalEnabled } from '../components/shared/environment';
import { transformToDto } from '../model/custom-grouping/customGrouping';
import { Flavor } from '../model/flavor';
import { AppState } from '../redux/configureStore';
import { defaultWorkspace } from '../redux/workspace';
import {
  DataType,
  defaultReportDefinition,
  defaultReportDefinitionAcm,
  defaultReportDefinitionSaccr,
  DESIGNER_SEQUENCE_ID,
  DrawerType,
  INITIAL_PENDING_STATUS,
  METADATA_SEQUENCE_ID,
  NodeType,
  VisualizationFriendlyNames,
} from './constants';
import {
  AdHocCustomGroupingDefinition,
  BaseItemType,
  Characteristic,
  CustomGroupingDefinition,
  DateContext,
  DrawerItemType,
  FolderListRow,
  LayerDefinition,
  Node,
  NodeWithParentId,
  PendingRequest,
  ReportData,
  ReportDefinition,
  Sandbox,
  Sandboxes,
  VizListIdName,
  WorkspaceData,
  WorkspacePayload,
  WorkspaceTab,
  WorkspaceTabs,
} from './dataTypes';

// use this function to generate an array with numbers from 0 up to n (excluding n)
export const naturalNumbersArray = (n: number): number[] => [...Array.from(Array(n).keys())];

export const formatNumber = (n: number): string =>
  n.toLocaleString(undefined, { maximumFractionDigits: 0 });

export { mapData, nextRequestId, nextSequenceId };

export const VISUALIZATIONS_WITH_LAYOUT = [
  'BAR_CHART',
  'STACKED_BAR_CHART',
  'MIXED_BAR_CHART',
  'LINE_GRAPH',
  'XY_LINE_CHART',
  'AREA_CHART',
  'XY_AREA_CHART',
  'COMPOSED_CHART',
];

export const findWorkspaceBottom = (
  tab: WorkspaceTab,
): { lgY: number; mdY: number; smY: number } => ({
  lgY: tab.layouts?.lg?.length ? Math.max(...tab.layouts.lg.map(layout => layout.y)) + 1 : 0,
  mdY: tab.layouts?.md?.length ? Math.max(...tab.layouts.md.map(layout => layout.y)) + 1 : 0,
  smY: tab.layouts?.sm?.length ? Math.max(...tab.layouts.sm.map(layout => layout.y)) + 1 : 0,
});

export const extractWorkspacePayloadAttr = (
  attr: keyof WorkspacePayload,
  sequenceId: number,
  state: AppState,
): any => {
  if (!state.workspace.data) {
    return null;
  }
  return getReportWorkspacePayload(sequenceId, state)?.[attr] || null;
};

export const getReportWorkspacePayload = (
  sequenceId: number,
  state: AppState,
): WorkspacePayload => {
  if (sequenceId === DESIGNER_SEQUENCE_ID) {
    return state.reportDesigner.designerWorkspacePayload;
  }

  const tabId = findTabId(sequenceId, state.workspace.data);

  return Object.values(state.workspace.data?.tabs?.[tabId]?.reports || {}).filter(
    report => report.sequenceId === sequenceId,
  )[0];
};

export function isEmpty(o) {
  for (let i in o) {
    if (o.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
}

export const clientUuid = uuidv4();

export const columnAlignment = (dataType: Number) => {
  switch (dataType) {
    case 1:
    case 5:
    case 6:
    case 7:
      return 'left';
    case 2:
    case 3:
    case 8:
    case 9:
    case 10:
    case 11:
      return 'right';
    default:
      return 'left';
  }
};

export const getSelectedVisualization = (reportDefinition: ReportDefinition): string => {
  const slot = reportDefinition?.menuComplications?.find(
    c => c.complicationDetails.type === 'VisualizationComplication',
  );

  return slot ? slot.complicationDetails.selectedOption : 'DX_TABLE';
};

export const escapeCommas = (cell: string) =>
  typeof cell === 'string' && String(cell).includes(',') ? '"' + cell + '"' : cell;

export const median = arr => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const lookupWorkspaceReports = (workspaceData: WorkspaceData): WorkspacePayload[] => {
  if (!workspaceData) return [];

  return Object.values(workspaceData.tabs).flatMap(tab => Object.values(tab.reports));
};

export const findTabId = (sequenceId: number, workspaceData: WorkspaceData): number => {
  const tabId = Object.entries(workspaceData.tabs).find(entry =>
    Object.keys(entry[1].reports).includes(`${sequenceId}`),
  );
  return tabId ? Number(tabId[0]) : null;
};

export const maxTabId = (tabs: WorkspaceTabs) =>
  Math.max(...Object.keys(tabs).map(id => Number(id)));

export const minTabId = (tabs: WorkspaceTabs) =>
  Math.min(...Object.keys(tabs).map(id => Number(id)));

export const hasTabId = (tabs: WorkspaceTabs, tabId: string | number) =>
  Object.keys(tabs).some(id => Number(id) === Number(tabId));

export const getNumPendingRequests = (reportData: { [key: number]: ReportData }) => {
  let num = 0;

  Object.keys(reportData).forEach(key => {
    num += reportData[key].pendingRequests
      ? Object.keys(reportData[key].pendingRequests).filter(
          value => value !== INITIAL_PENDING_STATUS,
        ).length
      : 0;
  });

  return num;
};

export const checkValidation = (dataType: DataType, val: any, allowsEmpty?: boolean): string => {
  if (!val || val === '') {
    if (allowsEmpty) {
      return null;
    } else {
      return 'required';
    }
  }

  switch (dataType) {
    case DataType.DECIMAL: {
      if (isNaN(parseFloat(val))) {
        return 'invalid format';
      }
      break;
    }
    case DataType.NUMBER: {
      if (isNaN(parseInt(val)) || !Number.isInteger(parseInt(val))) {
        return 'invalid format';
      }
      break;
    }
    case DataType.BOOLEAN:
    case DataType.DATE:
    case DataType.STRING:
    default: {
      return null;
    }
  }
  return null;
};

//Once Min Max are valid, check if Min is less than the Max value(Extra validator)
export const minMaxValidator = (dataType: DataType, minVal: any, maxVal: any): string => {
  if (!minVal || minVal === '' || !maxVal || maxVal === '') {
    return null;
  }

  switch (dataType) {
    case DataType.DECIMAL: {
      if (parseFloat(minVal) > parseFloat(maxVal)) {
        return 'invalid range';
      }
      break;
    }
    case DataType.NUMBER: {
      if (parseInt(minVal) > parseInt(maxVal)) {
        return 'invalid range';
      }
      break;
    }
    case DataType.DATE: {
      const minTime = new Date(minVal).getTime();
      const maxTime = new Date(maxVal).getTime();

      if (isNaN(minTime) || isNaN(maxTime)) {
        return null;
      }

      if (minTime > maxTime) {
        return 'invalid range';
      }
      break;
    }
    case DataType.STRING: {
      if (minVal > maxVal) {
        return 'invalid range';
      }
      break;
    }
    case DataType.BOOLEAN:
    default: {
      return null;
    }
  }

  return null;
};

export const validateScenarioInputsMsg = (value: number, min: number, max: number) => {
  if (value < min || value > max) {
    return 'Invalid value';
  } else {
    return null;
  }
};

export const charToLayerDef = (value: Characteristic): LayerDefinition => ({
  layerId: value.charId,
  linkedGroupingPath: value.linkedGroupingPath,
  modifier: value.modifier,
  grouperData: value.grouperData,
  breakpoints: value.breakpoints,
  ...(value.customGrouping
    ? { customGrouping: value.customGrouping }
    : isCharCustomGrouping(value)
    ? {
        customGrouping: {
          id: value.id,
          name: value.name ?? 'Untitled Custom Grouping',
          description: value.description,
          owner: value.owner,
          rootNode: null,
        },
      }
    : {}),
});

export const customGroupingToLayerDef = (
  customGrouping: AdHocCustomGroupingDefinition,
): LayerDefinition => ({
  layerId: GroupingLayerId.CUSTOM_GROUPING,
  linkedGroupingPath: undefined,
  modifier: Modifier.PORT,
  customGrouping,
});

export const updateLayersWithCustomGrouping = (
  layers: LayerDefinition[],
  grouping: AdHocCustomGroupingDefinition,
  removePrevious: boolean,
) => {
  // If there is a detail layer, we set it aside, as it must come last.
  const detailLayerIndex = layers.findIndex(({ layerId }) => layerId === GroupingLayerId.DETAIL);
  const nonDetailLayers = layers.filter(({ layerId }) => layerId !== GroupingLayerId.DETAIL);

  // Depending on whether a Custom Grouping with this ID is already in the
  // layer definitions, we either append or update as appropriate.
  const groupingLayerIndex = nonDetailLayers.findIndex(
    layer =>
      layer.layerId === GroupingLayerId.CUSTOM_GROUPING && layer.customGrouping.id === grouping.id,
  );

  return [
    ...(groupingLayerIndex === -1
      ? [
          ...nonDetailLayers,
          customGroupingToLayerDef(removePrevious ? { ...grouping, id: uuidv4() } : grouping),
        ]
      : nonDetailLayers.map((layer, index) =>
          index !== groupingLayerIndex
            ? layer
            : customGroupingToLayerDef(removePrevious ? { ...grouping, id: uuidv4() } : grouping),
        )),
    ...(detailLayerIndex === -1 ? [] : [layers[detailLayerIndex]]),
  ];
};

export const isCharCustomGrouping = (char: Characteristic) =>
  char.charId === GroupingLayerId.CUSTOM_GROUPING;

export const isGroupingLayer = (char: Characteristic): boolean =>
  [GroupingLayerId.LINKED, GroupingLayerId.DETAIL, GroupingLayerId.DATASET].includes(char.charId) &&
  char.isGroupingLayer;

export const isCharsEqual = (charA: Characteristic, charB: Characteristic) =>
  charA.name === charB.name && charA.modifier === charB.modifier && charA.charId === charB.charId;

export const isCharEqualToLayer = (char: Characteristic, layer: LayerDefinition) =>
  char.charId === layer.layerId &&
  char.modifier === layer.modifier &&
  (!layer.customGrouping || layer.customGrouping?.id === char.customGrouping?.id);

export const isChartInverted = (reportDefinition: ReportDefinition) =>
  reportDefinition?.slotComplications?.some(
    slot =>
      slot.complicationDetails.type === 'ChartLayoutComplication' &&
      slot.complicationDetails.selectedOption === 'VERTICAL',
  );

export const isElementInViewport = (el: Element) => {
  const rect = el.getBoundingClientRect();
  return rect.bottom <= window.innerHeight && rect.top > 0;
};

export const getEndpoint = (
  nodeType: NodeType,
  drawerType: DrawerType,
  action: 'create' | 'delete' | 'move',
): 'reportDefinition' | 'workspaceDefinition' | 'moveReport' | 'moveWorkspace' => {
  switch (nodeType) {
    case NodeType.FOLDER:
      if (
        [
          DrawerType.OPEN_REPORT_IN_WORKSPACE,
          DrawerType.OPEN_REPORT_IN_DESIGNER,
          DrawerType.SAVE_REPORT,
        ].includes(drawerType)
      ) {
        if (['create', 'delete'].includes(action)) return 'reportDefinition';
        else return 'moveReport';
      } else {
        if (['create', 'delete'].includes(action)) return 'workspaceDefinition';
        else return 'moveWorkspace';
      }
    case NodeType.REPORT:
    case NodeType.TEMPLATE:
      if (['create', 'delete'].includes(action)) return 'reportDefinition';
      else return 'moveReport';
    case NodeType.WORKSPACE:
      if (['create', 'delete'].includes(action)) return 'workspaceDefinition';
      else return 'moveWorkspace';
  }
};

const hasLegacyReportsOrWorkspaces = (c: Node<string>) =>
  !(c.children === null && (c.name === 'Legacy Reports' || c.name === 'Legacy Workspaces'));

export const markFolders = (data: Node<string>, isLegacy?: boolean): Node<string> => {
  if (!data.children)
    return {
      ...data,
      name: decodeURIComponent(data.name),
      props: { ...data.props, isLegacy },
    };
  else {
    const isLegacyNext =
      ['Legacy Reports', 'Legacy Workspaces'].includes(data.name) || isLegacy === true;

    return {
      ...data,
      name: decodeURIComponent(data.name),
      props: { ...data.props, isLegacy: isLegacyNext },
      children: data.children
        .filter(hasLegacyReportsOrWorkspaces)
        .map(c => markFolders(c, isLegacyNext))
        .sort((a, b) =>
          ['Legacy Reports', 'Legacy Workspaces'].includes(a.name)
            ? 1
            : ['Legacy Reports', 'Legacy Workspaces'].includes(b.name)
            ? -1
            : a.name.localeCompare(b.name),
        ),
    };
  }
};

export const supportsDiscreteBreakpoints = (dataType: DataType) =>
  [DataType.DECIMAL, DataType.NUMBER].includes(dataType);

export const treeListItemIsDisabled = (data: FolderListRow, drawerType: DrawerType) =>
  ([DrawerType.SAVE_REPORT, DrawerType.SAVE_WORKSPACE].includes(drawerType) &&
    ![NodeType.FOLDER, NodeType.TEMPLATE, NodeType.WORKSPACE].includes(data.type)) ||
  ([DrawerType.IMPORT_REPORT_TO_FOLDER, DrawerType.IMPORT_WORKSPACE_TO_FOLDER].includes(
    drawerType,
  ) &&
    NodeType.FOLDER !== data.type);

export const searchDataForPath = (data: Node<string>, path: string) =>
  data.props?.path === path
    ? data
    : data.children?.reduce((acc, cur) => acc || searchDataForPath(cur, path), null);

//Linked Group Utils
export const hasLinkedGroup = (chars: Characteristic[]): boolean =>
  chars.some(({ charId }) => charId === GroupingLayerId.LINKED);

export const hasPendingLinkedGroups = ({
  charId,
  linkedGroupingPath,
}: {
  charId: number;
  linkedGroupingPath?: string;
}): boolean => charId === GroupingLayerId.LINKED && !linkedGroupingPath;

export const hasNoPendingLinkedGroups = (chars: Characteristic[]): boolean =>
  !chars.some(hasPendingLinkedGroups);

// Breakpoint Utils
export const hasPendingBreakpoints = ({
  dataType,
  breakpoints,
  grouperEditor,
}: {
  dataType: DataType;
  breakpoints?: string[];
  grouperEditor?: string;
}) => grouperEditor == null && supportsDiscreteBreakpoints(dataType) && !breakpoints;

export const hasNoPendingBreakpoints = (chars: Characteristic[]): boolean =>
  !chars.some(hasPendingBreakpoints);

export const visualizationsList: VizListIdName[] = Object.keys(visualizationComponents)
  .filter(key => key !== 'BULLET_GRAPH')
  .map(key => ({
    id: key,
    name: VisualizationFriendlyNames[key],
  }));

// Given a visualization option (e.g. DX_TABLE), return an object that contains
// that option as the selected option as well as all the visualizations that are
// appropriate visualization options for that selection. For a DX_TABLE, all
// visualizations are allowed. For all others, just the option itself and DX_TABLE
// are allowed.
export const visualizationChoicesForSelection = (option: string) => {
  switch (option) {
    case 'MAP_CHART':
    case 'TREE_MAP':
      return {
        selectedOption: option,
        // options: ['DX_TABLE', 'MAP_CHART', 'TREE_MAP'],
        options: ['DX_TABLE', 'TREE_MAP'],
      };
    case 'BAR_CHART':
    case 'STACKED_BAR_CHART':
    case 'MIXED_BAR_CHART':
    case 'BRUSH_BAR_CHART':
    case 'STACKED_BRUSH_BAR_CHART':
    case 'LINE_GRAPH':
    case 'PIE_CHART':
    case 'AREA_CHART':
    case 'ACTIVE_PIE_CHART':
    case 'TWO_LEVEL_PIE_CHART':
    case 'COMPOSED_CHART':
    case 'RADIAL_BAR_CHART':
      return {
        selectedOption: option,
        options: ['DX_TABLE'].concat(
          [
            'BAR_CHART',
            'STACKED_BAR_CHART',
            'MIXED_BAR_CHART',
            'BRUSH_BAR_CHART',
            'STACKED_BRUSH_BAR_CHART',
            'LINE_GRAPH',
            'XY_LINE_CHART',
            'PIE_CHART',
            'AREA_CHART',
            'XY_AREA_CHART',
            'ACTIVE_PIE_CHART',
            'TWO_LEVEL_PIE_CHART',
            'COMPOSED_CHART',
            'RADIAL_BAR_CHART',
          ].sort(),
        ),
      };
    case 'SCATTER_CHART':
    case 'BUBBLE_CHART':
      return {
        selectedOption: option,
        options: ['DX_TABLE', 'BUBBLE_CHART', 'SCATTER_CHART'],
      };
    // case 'BULLET_GRAPH':
    //   return { selectedOption: option, options: ['DX_TABLE', 'BULLET_GRAPH'] };
    case 'DX_TABLE':
    default: {
      return { selectedOption: option, options: visualizationsList.map(item => item.id) };
    }
  }
};

export const getDateContextDisplayString = (dateContext: DateContext): string => {
  switch (dateContext.type) {
    case 'asOf':
      const { date, id } = dateContext.dates?.[0] || {};
      return `${date}${id ? ` (${id})` : ''}`;
    case 'between':
      return dateContext.dates?.map(d => d.date).join(' to ') || '';
    case 'specific':
      return dateContext.dates?.map(d => `${d.date}${d.id ? ` (${d.id})` : ''}`).join(', ');
    default:
      return 'Default';
  }
};

export const getDateContextStringWithUnderscore = (
  dateContext: DateContext,
  defaultDateContext?: FullDateContextItem,
): string => {
  switch (dateContext.type) {
    case 'asOf':
      return (
        dateContext.dates[0]?.date +
        (dateContext.dates[0]?.id ? `_${dateContext.dates[0]?.id}` : '')
      );
    case 'between':
      return dateContext.dates.map(d => d.date).join(',');
    case 'specific':
      return dateContext.dates.map(d => d.date + '_' + (d.id ? d.id : '')).join(',');
    case 'default':
      if (defaultDateContext)
        return defaultDateContext?.date + '_' + (defaultDateContext?.id || '');
      else return '';
  }
};

export const getDateContextForPortfolioDrawer = (
  defaultDateContext: FullDateContextItem,
  dateContext: DateContext,
): FullDateContextItem => {
  if (dateContext?.type === 'default') return defaultDateContext;
  else if (dateContext?.type === 'specific') return dateContext.dates[0];
  else if (dateContext?.type === 'asOf') return dateContext.dates[0];
  else if (dateContext?.type === 'between') return dateContext.dates[1];
};

// Function that returns an array with:
// 1. Nodes with path that includes the search term
// 2. All ancestors of those nodes
export const filterDataSource = <Id extends string | number = string | number>(
  nodes: NodeWithParentId<Id>[],
  searchTerm: string,
  getIsEncoded: (node: NodeWithParentId<Id>) => boolean,
  getSearchable: (node: NodeWithParentId<Id>) => string,
): NodeWithParentId<Id>[] => {
  if (!searchTerm) return nodes;

  const idsToKeep = new Set<Id>();

  nodes.forEach(node => {
    const nodeVal = getIsEncoded(node)
      ? decodeURIComponent(getSearchable(node)?.toLowerCase())
      : getSearchable(node)?.toLowerCase();

    if (nodeVal?.includes(searchTerm.toLowerCase())) {
      idsToKeep.add(node.id);

      // also grab all children of the node
      getAllChildNodes(node).forEach(child => {
        idsToKeep.add(child.id);
      });

      // Go up the tree to add the parent, then the parent's parent, etc.
      getAllParentNodes(nodes, node).forEach(parent => {
        idsToKeep.add(parent.id);
      });
    }
  });

  return nodes.filter(n => idsToKeep.has(n.id));
};

export const getAllParentNodes = (
  dataSource: NodeWithParentId[],
  node: NodeWithParentId | null,
) => {
  let nextNode = node;
  let nodes = [nextNode];
  while (dataSource?.map(n => n.id).includes(nextNode?.id)) {
    nodes = [...nodes, nextNode];
    const parentIdCopy = nextNode.parentId; // eslint complains if you refer to parentId in the find below.
    nextNode = dataSource.find(n => n.id === parentIdCopy);
  }
  return nodes;
};

export const getAllChildNodes = (node: Node<Cell[]> | null) =>
  node.children?.reduce((acc, curr) => [...acc, curr, ...getAllChildNodes(curr)], []) || [];

export const isNodeSelected = <Id extends string | number = string | number>(
  selectedLeafIds: Id[],
  node: Node<Cell[]>,
) =>
  !node.children
    ? selectedLeafIds.includes(node.id as Id)
    : node.children.every(child => isNodeSelected(selectedLeafIds, child));

export const buildSandboxesFromTree = (parentItem: Sandboxes['public']) => {
  if (parentItem?.children) {
    return parentItem.children.map(buildSandboxesFromTree);
  }
  return parentItem?.props?.path;
};

export const sandboxesFromTree = (sandboxes: Sandboxes) =>
  sandboxes.public.children?.map(buildSandboxesFromTree).flat(Infinity);

export const getSandbox = (state: AppState, sandboxPath: string | null): Sandbox | null =>
  !sandboxPath
    ? null
    : {
        path: sandboxPath,
        prvSandbox: getIsPrivateSandbox(state, sandboxPath),
      };

export const getIsPrivateSandbox = (state: AppState, sandboxPath: string | null) =>
  sandboxPath !== null &&
  (state.user.userInfo.userPreferences.sandboxes.private?.includes(sandboxPath) ||
    !sandboxesFromTree(state.user.userInfo.userPreferences.sandboxes)?.includes(sandboxPath));

export const lookupLatestPendingRequest = (pendingRequests: {
  [requestId: string]: PendingRequest;
}) =>
  Object.values(pendingRequests ?? {}).sort((a, b) => b.requestTimestamp - a.requestTimestamp)[0]
    ?.requestStatus;

export const getRecentWorkspaces = (state: AppState) => state.user.userInfo?.recentWorkspaces ?? [];

export const getDateContextsArray = (dateContexts: DateContexts): FullDateContextItem[] =>
  Object.entries(dateContexts)
    .flatMap<FullDateContextItem>(d =>
      d[1].map(run => ({ date: d[0], id: run.id, published: run.published })),
    )
    .sort((a, b) => {
      if (a.date > b.date) return -1;
      else if (b.date < a.date) return 1;
      else if (a.id > b.id) return 1;
      else if (b.id < a.id) return -1;
      else return 0;
    });

export const capitalizeFirstLetter = (value: string) =>
  `${value.charAt(0).toUpperCase()}${value.slice(1)}`;

export const getAboutCustomName = (state: AppState) =>
  state.user.userInfo.serverConfigs?.buildInfo?.custom?.name;

export const processAboutCustomName = (name?: string) => {
  switch (name?.toLowerCase()) {
    case 'awa':
      return name.toUpperCase();
    default:
      return capitalizeFirstLetter(name);
  }
};

export const getAboutCustomContent = (state: AppState): Object =>
  state.user.userInfo.serverConfigs?.buildInfo?.[getAboutCustomName(state)] ?? {};

export const isAwa = (state: AppState) => state.user.userInfo?.serverConfigs?.connector === 'awa';

export const isAcm = (state: AppState) => state.user.userInfo?.serverConfigs?.connector === 'ACM';

/**
 * Return an array of all installed flavors.
 */
export const getFlavors = (state: AppState) =>
  (state.user.userInfo?.serverConfigs?.buildInfo?.awa?.flavors?.toLowerCase().split(',') ?? [])
    .map(flavor => flavor.trim())
    .filter(flavor => flavor !== '') as Flavor[];

/**
 * Return whether the target flavor is installed.
 */
export const hasFlavor = (state: AppState) => (flavor: Flavor) =>
  getFlavors(state).includes(flavor);

/**
 * Return whether *any* of the target flavors is installed.
 */
export const hasAnyFlavor = (state: AppState) => (...targetFlavors: Flavor[]) =>
  targetFlavors.some(flavor => getFlavors(state).includes(flavor));

/**
 * Return whether *all* of the target flavors are installed.
 */
export const hasAllFlavors = (state: AppState) => (...targetFlavors: Flavor[]) =>
  targetFlavors.every(flavor => getFlavors(state).includes(flavor));

export const hasSaccr = (state: AppState) => hasFlavor(state)(Flavor.SACCR);
export const hasSaccrOnly = (state: AppState) => {
  const flavors = getFlavors(state);

  return flavors.length === 1 && flavors[0] === Flavor.SACCR;
};

export const showPortfolioSelector = (state: AppState) =>
  isAwa(state) && hasAnyFlavor(state)(Flavor.MR, Flavor.FRTB, Flavor.ALMLR, Flavor.PA);

export function deDupeArray<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

const getSingleDateString = ({ date, id }: { date: string; id?: string }): string =>
  date + (id ? `_${id}` : '');

export const getDateContextString = (
  reportDateContext: DateContext,
  defaultDateContext: FullDateContextItem,
) =>
  reportDateContext?.type === 'default'
    ? getSingleDateString(defaultDateContext)
    : reportDateContext?.dates.map(getSingleDateString).join(',');

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    console.log(`debouncedValue = ${debouncedValue}`);
  }, [debouncedValue]);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

export const getCommonRequestProperties = (state: AppState, isMetadataRequest = false) => ({
  currency: state.user.userInfo.userPreferences.selectedCurrency,
  dateContext: state.user.selectedDateContext,

  // Exclude props irrelevant to metadata — these may break the request.
  ...(isMetadataRequest
    ? {}
    : {
        ...getSelectedPortfolios(state, null),
        selectedEntities: state.workspace.data?.selectedEntities,
      }),
});

export const getPortfoliosAtLevel = (
  portfolioHierarchy: Node<any>,
  level: number,
  isChild?: boolean,
): string[] =>
  level <= 1
    ? []
    : (isChild ? [] : [portfolioHierarchy.id]).concat(
        portfolioHierarchy.children?.flatMap(item => [
          item.id,
          ...getPortfoliosAtLevel(item, level - 1, true),
        ]) || [],
      );
export const searchPortfolioHierarchyForIds = (
  portfolioHierarchy: Node<string> | 'error',
  ids: string[],
): boolean => {
  if (portfolioHierarchy === 'error') {
    return false;
  }
  if (ids.includes(portfolioHierarchy.id)) {
    return true;
  } else {
    return portfolioHierarchy.children?.some(curr => searchPortfolioHierarchyForIds(curr, ids));
  }
};

export const searchPortfolioHierarchyForId = (
  portfolioHierarchy: Node<string> | 'error',
  id: string,
): boolean => searchPortfolioHierarchyForIds(portfolioHierarchy, [id]);

export const hasOpenWorkspace = (state: AppState) => !equal(state.workspace.data, defaultWorkspace);

export const resolveDefaultReportDefinition = (state: AppState) =>
  isAcm(state)
    ? defaultReportDefinitionAcm
    : hasSaccrOnly(state)
    ? defaultReportDefinitionSaccr
    : defaultReportDefinition;

/**
 * TODO: When `DrawerType` is moved to `drawerType.ts`, these should go there.
 */

export const getDrawerItemType = (type: DrawerType): DrawerItemType => {
  switch (type) {
    case DrawerType.OPEN_REPORT_IN_WORKSPACE:
    case DrawerType.OPEN_REPORT_IN_DESIGNER:
    case DrawerType.REPLACE_REPORT:
    case DrawerType.SAVE_REPORT:
    case DrawerType.IMPORT_REPORT_TO_FOLDER:
    case DrawerType.EXPORT_REPORTS:
      return 'REPORT';

    case DrawerType.OPEN_REPORT_TEMPLATE_IN_WORKSPACE:
      return 'REPORT_TEMPLATE';

    case DrawerType.OPEN_WORKSPACE:
    case DrawerType.SAVE_WORKSPACE:
    case DrawerType.APPEND_WORKSPACE:
    case DrawerType.IMPORT_WORKSPACE_TO_FOLDER:
    case DrawerType.EXPORT_WORKSPACES:
    case DrawerType.SELECT_DEFAULT_WORKSPACE:
      return 'WORKSPACE';

    case DrawerType.OPEN_WORKSPACE_TEMPLATE:
      return 'WORKSPACE_TEMPLATE';
  }
};

const isBaseItemType = (type: DrawerItemType): type is BaseItemType =>
  ['REPORT', 'WORKSPACE'].includes(type);

export const getExportType = (drawerType: DrawerType): BaseItemType | null => {
  const drawerItemType = getDrawerItemType(drawerType);
  return !isBaseItemType(drawerItemType) ||
    [DrawerType.IMPORT_REPORT_TO_FOLDER, DrawerType.IMPORT_WORKSPACE_TO_FOLDER].includes(drawerType)
    ? null
    : drawerItemType;
};

export const getEntityApiPath = (type: DrawerItemType) => {
  switch (type) {
    case 'REPORT':
      return 'reportFolders';

    case 'REPORT_TEMPLATE':
      return 'templateReportFolders';

    case 'WORKSPACE':
      return 'workspaceFolders';

    case 'WORKSPACE_TEMPLATE':
      return 'templateWorkspaceFolders';

    default:
      return null;
  }
};

/**
 * TODO: End `drawerType.ts` export block (see above comment).
 */

export const isReservedSequenceId = (sequenceId: number) =>
  [DESIGNER_SEQUENCE_ID, METADATA_SEQUENCE_ID].includes(sequenceId);

const getElementNChildrenIds = (
  element: string,
  data: Node<Cell[]>,
  include: boolean,
  n: number,
) => {
  const rowHash = data.payload?.[0]?.[RowField.ROW_HASH];

  return n && data?.children
    ? data.children?.reduce(
        (acc, curr) => [
          ...acc,
          ...getElementNChildrenIds(
            element,
            curr,
            include || rowHash === element,
            n - (include ? 1 : 0),
          ),
        ],
        include || rowHash === element ? [rowHash] : [],
      )
    : [];
};

export const getElementsNChildren = (data: Node<Cell[]>, elements: string[], n: number) =>
  elements?.reduce((acc, curr) => [...acc, ...getElementNChildrenIds(curr, data, false, n)], []) ||
  [];

const getElementTreeDepth = (data: Node<Cell[]>, element: string, include: boolean) =>
  data.children
    ? (include || data.payload?.[0]?.[RowField.ROW_HASH] === element ? 1 : 0) +
      Math.max(
        ...data.children.map(child =>
          getElementTreeDepth(
            child,
            element,
            include || data.payload?.[0]?.[RowField.ROW_HASH] === element,
          ),
        ),
      )
    : 0;

export const getElementsTreeDepth = (data: Node<Cell[]>, elements: string[] | null) =>
  Math.max(
    0,
    ...(elements ?? []).reduce((acc, curr) => [...acc, getElementTreeDepth(data, curr, false)], []),
  );

export const canAdminChangePassword = (state: AppState) =>
  state.user.userInfo.serverConfigs?.allowChangePassword &&
  !isOidcClientEnabled &&
  !isPortalEnabled;

export const getSelectedSandbox = (state: AppState, sequenceId: number) =>
  state.workspace?.data?.tabs[state.workspace.selectedTabIndex]?.reports[sequenceId]?.sandbox;

export const getReportDefinitionCurrency = (state: AppState, sequenceId: number) => {
  const reportDef = state.report.reportDefinition[sequenceId];
  const getParentCurrency =
    extractWorkspacePayloadAttr('drillThrough', sequenceId, state) &&
    reportDef?.settings?.drillThroughInheritance &&
    reportDef?.settings?.reportingCurrency;
  return getParentCurrency
    ? getReportDefinitionCurrency(
        state,
        extractWorkspacePayloadAttr('parentSequenceId', sequenceId, state),
      )
    : reportDef?.currency;
};

export const getCustomGroupingValue = (state: AppState, sequenceId: number, key: string) => {
  const reportDef = state.report.reportDefinition[sequenceId];
  const getParentKey =
    extractWorkspacePayloadAttr('drillThrough', sequenceId, state) &&
    reportDef?.settings?.drillThroughInheritance &&
    reportDef?.settings?.scenarioSettings;

  return getParentKey
    ? getCustomGroupingValue(
        state,
        extractWorkspacePayloadAttr('parentSequenceId', sequenceId, state),
        key,
      )
    : reportDef?.[key];
};

export const applyReportPrecisionUpdate = (
  report: ReportDefinition,
  { charId, modifier, precisionOffset }: ReportPrecisionUpdate,
): ReportDefinition => ({
  ...report,
  chars: report.chars.map(char => ({
    ...char,
    ...(char.charId === charId && char.modifier === modifier ? { precisionOffset } : {}),
  })),
});

export interface CreateObjectProps<Item, Key extends string, Value> {
  items: Item[];
  createKey?: (item: Item) => Key;
  createValue?: (item: Item) => Value;
}

export const createObject = <Item, Key extends string, Value = Item>({
  items,
  createKey = (item: Item) => (`${item}` as unknown) as Key,
  createValue = (item: Item) => (item as unknown) as Value,
}: CreateObjectProps<Item, Key, Value>): {
  [K in Key]: Value;
} =>
  Object.assign(
    {},
    ...items.map(item => ({
      [createKey(item)]: createValue(item),
    })),
  );

export interface MapObjectProps<
  InitialKey extends string,
  InitialValue,
  Key extends string = InitialKey,
  Value = InitialValue
> {
  initialObject: { [K in InitialKey]: InitialValue };
  mapKey?: (key: InitialKey) => Key;
  mapValue?: (value: InitialValue) => Value;
}

export const mapObject = <
  InitialKey extends string,
  InitialValue,
  Key extends string = InitialKey,
  Value = InitialValue
>({
  initialObject,
  mapKey = (key: InitialKey) => (key as unknown) as Key,
  mapValue = (value: InitialValue) => (value as unknown) as Value,
}: MapObjectProps<InitialKey, InitialValue, Key, Value>): {
  [K in Key]: Value;
} =>
  Object.assign(
    {},
    ...Object.entries<InitialValue>(initialObject).map(([initialKey, initialValue]) => ({
      [mapKey(initialKey as InitialKey)]: mapValue(initialValue),
    })),
  );

export const moveItem = <T>(items: T[], sourceIndex: number, targetIndex: number): T[] => {
  switch (Math.sign(targetIndex - sourceIndex)) {
    // targetIndex < sourceIndex
    case -1:
      return [
        ...items.slice(0, targetIndex),
        items[sourceIndex],
        ...items.slice(targetIndex, sourceIndex),
        ...items.slice(sourceIndex + 1),
      ];

    // targetIndex = sourceIndex
    case 0:
      return [...items];

    // targetIndex > sourceIndex
    case 1:
      return [
        ...items.slice(0, sourceIndex),
        ...items.slice(sourceIndex + 1, targetIndex + 1),
        items[sourceIndex],
        ...items.slice(targetIndex + 1),
      ];
  }
};

export const prettyPrint = <T>(value: T) => JSON.stringify(value, null, 2);

export const convertDefToDto = (def: ReportDefinition): ReportDefinition => ({
  ...def,
  verticalChars: def.verticalChars.map(c => {
    const customGrouping = c.customGrouping as CustomGroupingDefinition; // TODO: SD-2534
    return {
      ...c,
      customGrouping: customGrouping?.rootNode ? transformToDto(customGrouping) : customGrouping,
    };
  }),
  horizontalChars: def.horizontalChars.map(c => {
    const customGrouping = c.customGrouping as CustomGroupingDefinition; // TODO: SD-2534
    return {
      ...c,
      customGrouping: customGrouping?.rootNode ? transformToDto(customGrouping) : customGrouping,
    };
  }),
});

export const positionSearchFoliaTemplate = (input: string) =>
  `key[Instrument Name] == "*${input}*" OR (key[Position Name Only] == "*${input}*" AND key[Instrument Name] == null)`;

export const instrumentSearchFoliaTemplate = (input: string) =>
  `key[Instrument Name] == "*${input}*" || key[DataSet Name] == "*${input}*"`;

export const base64EncodeFile = (file: File) =>
  new Promise<string | ArrayBuffer>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });

// fetch converts base64 string to a Response and then .blob() converts it to a Blob
export const base64DecodeFile = (fileString: string) =>
  fetch(fileString).then(response => response.blob());
